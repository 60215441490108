import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
export default function Slider() {
    return (
        <div className="container-fluid">
            <div className="row mx-5">
                <div className="col-md-12">
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/images/slider-1.jpg"
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>Poética</h3>
                                <p>Aristóteles </p>
                                <button className='btn btn-outline-light'>Ver publicação</button>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/images/slider-2.jpg"
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>Sidereus Nuncius. Mensageiro das Estrelas</h3>
                                <p>Galileu Galilei  </p>
                                <button className='btn btn-outline-light'>Ver publicação</button>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/images/slider-3.jpg"
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>Princípios Matemáticos da Filosofia Natural</h3>
                                <p>Isaac Newton  </p>
                                <button className='btn btn-outline-light'>Ver publicação</button>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/images/slider-4.jpg"
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>Principia Ethica</h3>
                                <p>George Edward Moore </p>
                                <button className='btn btn-outline-light'>Ver publicação</button>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
