import { Link } from "react-router-dom";

export default function NftCard({ nft }) {

    return (
        <Link to={`/nft/${nft.id}`}>
            <div className="card nft p-4">
                <div className="cover">
                    <img
                        src={nft.image}
                        alt={`NFT ${nft.id}`}
                        className="nft-image"
                    />
                </div>
                <p className="text-uppercase text-muted">{nft?.category}</p>
                <h5 className='nft-title'>{nft?.name?.length > 40 ? (
                    <strong>{nft?.name?.substring(0, 40)}...</strong>
                ) : (
                    <strong>{nft?.name}</strong>
                )}</h5>
            </div>
        </Link>
    )
}
