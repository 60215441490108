import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import ABI from '../../utils/ABI.json';
import { Link } from 'react-router-dom'
import NftCard from '../Cards/NftCard';
const ContractAddress = '0xf6e11489c02e5ee84b0253f1b88dd770eb400750';
const ContractABI = ABI;
const web3Instance = new Web3('https://goerli.infura.io/v3/1a4e0b16869a40c4bc63f0d86384b197');

export default function NftList() {
    const [nftList, setNFTList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(10);
    const loadNFTs = async () => {
        try {
            setIsLoading(true);
            // Connect to the Ethereum network using web3Instance
            // if (window.ethereum) {
            //     //await window.ethereum.enable();
            // }
            // Get the contract instance
            const contract = new web3Instance.eth.Contract(ContractABI, ContractAddress);

            // Get the total number of NFTs
            const totalNFTs = await contract.methods.totalSupply().call();

            // Retrieve each NFT by its token ID
            const nftArray = [];
            for (let i = 0; i < totalNFTs; i++) {
                const tokenId = i + 1;
                const tokenURI = await contract.methods.tokenURI(tokenId).call();

                // Replace the image URL prefix
                const nftURL = tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/');
                //const nftURL = `https://cors-anywhere.herokuapp.com/${tokenURI}`;
                //const url = `http://localhost:8080?url=/${nftURL}`;
                // Fetch the metadata from the complete URL
                const response = await fetch(nftURL);
                const metadata = await response.json();
                const nftImage = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const nft = {
                    id: tokenId,
                    name: metadata.name,
                    image: nftImage,
                    category: metadata?.category,
                    attributes: metadata.attributes,
                };
                nftArray.push(nft);
            }

            setNFTList(nftArray);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching NFTs:', error);
            setIsLoading(false);
        }
    };

    const loadMoreItems = () => {
        const newItemsToShow = itemsToShow + 3;
        setItemsToShow(newItemsToShow);
    };

    useEffect(() => {
        loadNFTs();
        //eslint-disable-next-line
    }, [itemsToShow]);
    return (
        <div className='container my-4'>
            <h3>NOVAS PUBLICAÇÕES</h3>
            <div className="row row-cols-sm-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 mb-5">
                {nftList.slice(0, itemsToShow).map((nft, index) => (
                    <div className="col p-2" key={index}>
                        <NftCard nft={nft} />
                    </div>
                ))}
            </div>
        </div>
    )
}
