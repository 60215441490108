import React from 'react'

export default function Intro() {
    return (
        <div className='container'>
            <div className="row justify-content-center py-5">
                <div className="col-md-7">
                    <h1 className='mb-5'>Publicações Gulbenkian</h1>
                    <h4>A Fundação editou obras fundamentais para a cultura portuguesa, o ensino universitário e o conhecimento em geral. Ao adotar uma nova política editorial pretende tornar acessível, a todo o público, clássicos da cultura mundial e obras marcantes da cultura portuguesa.</h4>
                    <p>As edições e reedições serão primordialmente efetuadas em formato digital, com acesso gratuito e universal, desde que salvaguardados os direitos de autor.</p>
                    <a href="/" className='btn btn-dark text-white'>Ver publicações</a>
                </div>
            </div>
        </div>
    )
}
