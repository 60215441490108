import React from 'react'
import { AiOutlineFacebook, AiOutlineLinkedin, AiOutlineInstagram, AiFillTwitterSquare, AiOutlineYoutube } from 'react-icons/ai'
import { BsYoutube, BsTwitter } from 'react-icons/bs'
import { ImFacebook } from 'react-icons/im'
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa'
export default function Footer() {
    return (
        <div className='container-fluid bg-light'>
            <div className="container">
                <div className="row py-3">
                    <div className="col-md-4">
                        <h4 className='text-uppercase'>Newsletter</h4>
                        <div className="row">
                            <div className="col-md-8">
                                <input type="text" className='form-control' placeholder='Email' />
                            </div>
                            <div className="col-md-3">
                                <button className='btn btn-dark text-uppercase'>Subscrever</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4 className='text-uppercase'>SIGA-NOS</h4>
                        <ul className='nav'>
                            <li className="nav-item"><ImFacebook className='social-icon fs-2' /></li>
                            <li className="nav-item"><FaLinkedinIn className='social-icon fs-2' /></li>
                            <li className="nav-item"><FaInstagram className='social-icon fs-2' /></li>
                            <li className="nav-item"><BsTwitter className='social-icon fs-2' /></li>
                            <li className="nav-item"><BsYoutube className='social-icon fs-2' /></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4 className='text-uppercase'>INFORMAÇÕES</h4>
                        <address>
                            Av. de Berna, 45A, 1067-001 Lisboa<br />
                            +351 21 7823000<br />
                            Formulário de contacto
                        </address>
                    </div>
                </div>
                <div className="row border-top py-4">
                    <div className="col-md-12">
                        <ul className='nav'>
                            <li className="nav-item px-2">Newsletter</li>
                            <li className="nav-item px-2"> Recrutamento</li>
                            <li className="nav-item px-2"> Press Kit</li>
                            <li className="nav-item px-2"> Websites Associados</li>
                            <li className="nav-item px-2"> Perguntas Frequentes</li>
                            <li className="nav-item px-2"> Política da Qualidade</li>
                            <li className="nav-item px-2">Ética e Canal de Denúncias</li>
                            <li className="nav-item px-2"> Política de Privacidade e Cookies</li>
                            <li className="nav-item px-2"> Termos de Utilização</li>
                            <li className="nav-item px-2">Definições de Cookies</li>
                        </ul>
                    </div>
                </div>
                <div className="row border-top py-3">
                    <div className="col-md-10">
                        <p>©2023 Fundação Calouste Gulbenkian – Direitos reservados </p>
                    </div>
                    <div className="col-md-2">
                        <ul className="nav">
                            <li className="nav-item px-2 text-uppercase text-decoration-underline">PT</li>
                            <li className="nav-item px-2 text-uppercase">EN</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
