import React from 'react'

export default function Catalogs() {
    return (
        <div className='container'>
            <div className="row border-top py-5 justify-content-center">
                <div className="col-md-7">
                    <h2 className='mb-3'>Catálogo de Edições Gulbenkian 2022</h2>
                    <h6 className='mb-5'>Conheça o catálogo com todos os títulos e coleções/séries publicadas ao longo de seis décadas do Plano de Edições (1962-2022), disponíveis para compra ou download gratuito em formato digital.</h6>
                    <button className='btn btn-outline-dark'>Catálogo em PDF</button>
                </div>
            </div>
        </div>
    )
}
