import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Viewer } from '@react-pdf-viewer/core';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Web3 from 'web3';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ABI from '../../utils/ABI.json';
import { AiOutlineFacebook, AiOutlineTwitter } from 'react-icons/ai'
import { ImPinterest2, ImTumblr } from 'react-icons/im'
import truncateEthAddress from 'truncate-eth-address'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const ContractAddress = '0xf6e11489c02e5ee84b0253f1b88dd770eb400750';
const ContractABI = ABI;
const web3Instance = new Web3('https://goerli.infura.io/v3/1a4e0b16869a40c4bc63f0d86384b197');

export default function NftDetails() {
    const params = useParams();
    const nftId = parseInt(params.id); // Convert nftId to number
    const [nft, setNFT] = useState(null);
    const [tokenName, setTokenName] = useState();
    const [tokenSymbol, setTokenSymbol] = useState();
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null); // State to hold the Blob URL
    const loadNFTs = async () => {
        try {

            if (window.ethereum) {
                //await window.ethereum.enable();

                // Get the contract instance
                const contract = new web3Instance.eth.Contract(ContractABI, ContractAddress);

                // Get the total number of NFTs
                //   const totalNFTs = await contract.methods.totalSupply().call();
                const tokenname = await contract.methods.name().call();
                const tokensymbol = await contract.methods.symbol().call();
                setTokenName(tokenname)
                setTokenSymbol(tokensymbol)
                // Retrieve each NFT by its token ID
                const tokenURI = await contract.methods.tokenURI(nftId).call();
                // Replace the image URL prefix
                const nftURL = tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/');
                //const nftURL = `https://cors-anywhere.herokuapp.com/${tokenURI}`;
                // Fetch the metadata from the complete URL
                const response = await fetch(nftURL);
                const metadata = await response.json();
                const nftImage = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const nft = {
                    id: nftId,
                    name: metadata.name,
                    title: metadata?.title,
                    subtitle: metadata?.subtitle,
                    author: metadata?.author,
                    year_book: metadata?.year_book,
                    entity: metadata?.entity,
                    pages: metadata?.pages,
                    language: metadata?.language,
                    isbn: metadata?.isbn,
                    edition: metadata?.edition,
                    last_Edition: metadata?.last_Edition,
                    entity_2: metadata?.entity_2,
                    category: metadata?.category,
                    description: metadata?.description,
                    image: nftImage,
                    pdf: metadata?.pdf,
                    attributes: metadata.attributes,
                };
                // if (nft?.pdf) {
                //     // Fetch the PDF file and create a Blob URL
                //     axios
                //         .get(`https://cors-anywhere.herokuapp.com/${nft.pdf}`, { responseType: 'blob' })
                //         .then((response) => {
                //             const blobUrl = URL.createObjectURL(response.data);
                //             setNFT({ ...nft, pdf: blobUrl });
                //         })
                //         .catch((error) => {
                //             console.error('Error fetching PDF:', error);
                //         });
                // }
                setNFT(nft)
            }
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        if (nftId) {
            loadNFTs();
        }
        //eslint-disable-next-line
    }, [nftId]);
    return (
        <div className='container-fluid'>
            <div className="container">
                <div className='row py-5'>
                    <div className='col-md-5'>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">
                                {nft?.name}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className='mb-2'>{nft?.title}</h1>
                        <h4 className='mb-2'>{nft?.subtitle}</h4>
                        <p>{nft?.description}</p>
                        <center className='mb-3'><img src={nft?.image} alt={nft?.name} style={{ height: '200px' }} /></center>
                    </div>
                    <div className='col-md-7'>
                        {nft?.pdf ? (
                            <div
                                style={{
                                    height: '40rem',
                                }}
                            >
                                <Viewer fileUrl={nft?.pdf} plugins={[defaultLayoutPluginInstance]} />
                            </div>
                        ) : (
                            <img src={nft?.image} alt={nft?.name} className='img-fluid' />
                        )}
                        <center><button className='btn btn-dark text-white my-3'>ADICIONAR NO MEU CLUBE</button></center>

                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="profile" title="FICHA TÉCNICA">
                                <h4 className='my-2'>FICHA TÉCNICA</h4>
                                <div><strong>Textos:</strong> {nft?.author}</div>
                                <div><strong>Idioma:</strong> {nft?.language}</div>
                                <div><strong>Editado:</strong> {nft?.year_book}</div>
                                <div><strong>Entidade:</strong> {nft?.entity}</div>
                                <div><strong>Páginas:</strong> {nft?.pages}</div>
                                <div><strong>Título Original:</strong> {nft?.title}</div>
                                <div><strong>ISBN:</strong> {nft?.isbn}</div>
                                <div className="share border-top d-flex align-items-center mt-5">
                                    <strong>Partilhar no:</strong>
                                    <ul className='nav'>
                                        <li><AiOutlineFacebook className='fs-3 m-2' /></li>
                                        <li><AiOutlineTwitter className='fs-3 m-2' /></li>
                                        <li><ImPinterest2 className='fs-3 m-2' /></li>
                                        <li><ImTumblr className='fs-3 m-2' /></li>
                                    </ul>
                                </div>
                            </Tab>
                            <Tab eventKey="blockchain" title="blockchain">
                                <h5 className='mt-3'>Blockchain Details</h5>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <strong>Token Name</strong>
                                    </div>
                                    <div className="col-md-6 float-end">
                                        {tokenName}({tokenSymbol})
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <strong>Contract Address</strong>
                                    </div>
                                    <div className="col-md-6 float-end">
                                        <a href={`https://goerli.etherscan.io/address/${ContractAddress}`} target='_blank' rel='noreferrer' className='text-primary'>{truncateEthAddress('0x290350a5c68cf607079e3fae73ba4975ed080f1a')}</a>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <strong>Token ID</strong>
                                    </div>
                                    <div className="col-md-6 float-end">
                                        {nftId}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-md-6">
                                        <strong>Token Standard</strong>
                                    </div>
                                    <div className="col-md-6 float-end">
                                        ERC-721
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <strong>Chain</strong>
                                    </div>
                                    <div className="col-md-6 float-end">
                                        Goerli
                                    </div>
                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}
