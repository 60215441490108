import React from 'react'
import Catalogs from '../../Components/Home/Catalogs'
import Intro from '../../Components/Home/Intro'
import NftList from '../../Components/Home/NftList'
import Slider from '../../Components/Home/Slider'

export default function Home() {
    return (
        <div>
            <Slider />
            <Intro />
            <NftList />
            <Catalogs />
        </div>
    )
}
