import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { MdOutlineCalendarMonth } from 'react-icons/md'
import { BsSearch } from 'react-icons/bs'
import { AiOutlineMenu } from 'react-icons/ai'
export default function Header() {
    return (
        <div>
            <Navbar bg="black" expand="lg" className='container-fluid border-bottom'>
                <div className="container">
                    <div className="row w-100 py-2">
                        <div className="col-md-6">
                            <Navbar.Brand href="/" className='ms-5'>
                                <Link to="/"> <img src='/images/logo.svg' alt='logo' className='logo' /></Link>
                            </Navbar.Brand>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <div>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="">
                                        <Nav.Link href="#home"><BiUserCircle className='nav-icon' /></Nav.Link>
                                        <Nav.Link href="#link"><HiOutlineShoppingBag className='nav-icon' /></Nav.Link>
                                        <Nav.Link href="#link"><MdOutlineCalendarMonth className='nav-icon' /></Nav.Link>
                                        <Nav.Link href="#link"><BsSearch className='nav-icon' /></Nav.Link>
                                        <Nav.Link href="#link"><AiOutlineMenu className='nav-icon' /></Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}
